<template>
  <div class="page1">
    <!--停车记录查询-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="所属区域" prop="areaId">
                <a-cascader
                  @change="handleAreaChange"
                  ref="cascader"
                ></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="areaIds"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  :dataSource="2"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Channel_name')">
                <el-autocomplete
                  :disabled="formInline.channelDisabled"
                  v-model="entryName"
                  :fetch-suggestions="querySearchAsyncEntry"
                  value-key="parkChannelName"
                  placeholder="请输入内容"
                  @select="handleSelectEntry"
                  @input="changeEntryName"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_name')">
                <el-select v-model.trim="formInline.type" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="识别一体机" value="0"></el-option>
                  <el-option label="云盒" value="1"></el-option>
                  <el-option label="主控机" value="2"></el-option>
                  <el-option label="车道感知机器人" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Offline_time')">
                <el-date-picker
                  v-model="formInline.disconnectTime"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                v-if="$route.meta.authority.button.query"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button icon="el-icon-delete" @click="resetForm()"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                v-if="$route.meta.authority.button.export"
                icon="el-icon-upload2"
                @click="exportFile()"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>

          <!-- <p style="color: #909399">说明：导出数据最多一次导出10,000条，如果筛选条件范围较大，则只能导出前10,000条。</p> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          stripe
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponentRoadside";
export default {
  name: "parkingStatusRecord",
  data() {
    return {
      flag: false,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      areaList: [],
      areaList2: [],
      entryName: "",
      rule: {},
      tableCols: [
        {
          prop: "index",
          // label: "序号",
          label: this.$t("list.index"),
          width: "70",
        },
        {
          prop: "areaName",
          // label: this.$t("list.Belonging_region"),
          label: this.$t("list.Belonging_region"),
          width: "",
        },
        {
          prop: "parkName",
          // label: this.$t("list.park_name"),
          label: this.$t("list.park_name"),
          width: "",
        },

        {
          prop: "channelName",
          // label: this.$t("list.Channel_name"),
          label: this.$t("list.Channel_name"),
          width: "",
        },
        {
          prop: "type",
          // label: this.$t("list.Equipment_name"),
          label: this.$t("list.Equipment_name"),
          width: "",
          formatter: (row, column, value) => {
            // return ["识别一体机", "云盒", "主控机", "车道感知机器人"][value];
            return [
              this.$t("list.Identification_all_in_one_machine"),
              this.$t("list.Cloud_Box"),
              this.$t("list.Main_control_machine"),
              this.$t("list.Lane_perception_robot"),
            ][value];
          },
        },
        {
          prop: "disconnectTime",
          // label: this.$t("list.Offline_time"),
          label: this.$t("list.Offline_time"),
          width: "",
        },
        {
          prop: "recoveryTime",
          // label: this.$t("list.recovery_time"),
          label: this.$t("list.recovery_time"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        disconnectTime: "",
        type: "",
        channelId: "",
        channelDisabled: true,
      },
      areaIds: "",
    };
  },
  methods: {
    changeEntryName() {
      if (this.entryName == "") {
        this.formInline.channelId = "";
      }
    },
    handleSelectEntry(item) {
      console.log(item, "ewww");
      this.formInline.channelId = item.parkChannelId;
    },
    querySearchAsyncEntry(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bacb/systems/loginUser/getChannelName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            channelName: queryString,
            parkId: this.formInline.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value && res.value.length) {
              cb(res.value);
            } else {
              cb([]);
            }
          }
        });
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        type: "",
        disconnectTime: "",
        channelDisabled: true,
      };
      this.entryName = "";
      this.areaId = "";
      this.streetId = "";
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.cascader.clear();
      this.$refs.parkInput.setValue();
      this.pageNum = 1;
      this.searParkRecordList();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
        this.formInline.channelDisabled = false;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
        this.entryName = "";
        this.formInline.channelId = "";
        this.formInline.channelDisabled = true;
      }
    },
    plateNumberSelect(item) {},
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    searParkRecordListFun() {
      const { parkId, operationId, disconnectTime, type, channelId } =
        this.formInline;
      let opt = {
        method: "get",
        url: "/acb/2.0/bacb/disconnectLog/list",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          areaId: this.$refs.cascader.getStreetId()
            ? this.$refs.cascader.getStreetId()
            : this.$refs.cascader.getAreaId(),
          parkId,
          operationId,
          disconnectTime,
          type,
          channelId,
        },
        success: (res) => {
          this.loading = false;
          if (res.state === 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          }
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    // 搜索
    searParkRecordList() {
      this.$refs["searchWrapper"].validate((v) => {
        if (v) {
          this.loading = true;
          this.flag = false;
          this.$refs.parkInput.setShowVal(this.formInline.parkName);
          this.searParkRecordListFun();
        }
      });
    },
    // 导出
    exportFile() {
      let opt = {};
      opt = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        areaId: this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId(),
        parkId: this.formInline.parkId,
        operationId: this.formInline.operationId,
        disconnectTime: this.formInline.disconnectTime,
        type: this.formInline.type,
        channelId: this.formInline.channelId,
      };
      exportExcelNew("/acb/2.0/bacb/disconnectLog/export", opt);
    },
  },
  components: {
    myComponent,
  },
  created() {
    this.getArea();
    this.flag = true;
  },
  mounted() {
    this.searParkRecordList();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
  }
}
</style>
